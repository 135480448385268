<template>
  <div class="Tests">
    <span
      lang="ru"
      style="position: relative;
  display: block;
  padding: 20px;
  border-radius: 12px;
  background-color: crimson;
  width: 95%;
  max-width: 600px;
  margin: 0% auto;
  hyphens: auto;"
      >Если был произведен перенос компетенций условно "NLP&gt;Data Science", а
      затем вы хотите сделать перенос "Image processing&gt;Data Science" -
      Крайне желательно обновить страницу
    </span>

    <div class="Buttons" v-if="!ButtonsBlocked">
      <template v-if="UsersList.length == 0">
        <GradientButton
          ButtonText="Загрузить список пользователей"
          @click.native="getUsers"
        />
      </template>
      <template v-else>
        <GradientButton
          v-if="TransferToSection != -1"
          ButtonText="Сохранить и Применить ко всем"
          @click.native="applyTransferForAllUsers"
        />
        <GradientButton
          v-if="TransferToSection != -1"
          ButtonText="Откатить изменения"
          @click.native="declineTransferForAllUsers"
        />
      </template>
    </div>

    <!-- <details class="Advanced noselect">
      <summary>Дополнительные настройки запросов (Опционально)</summary>
      <div class="Buttons" v-if="!ButtonsBlocked">
        <div class="Block">
          <label for="TickTimeout"
            >Время, через которое будет выполнен новый запрос после получения
            ответа от предыдущего (в мс)</label
          >
          <input type="number" v-model="TickSleep" />
        </div>

        <div class="Block">
          <label for="UsersPerTick"
            >Количество пользователей, которое будет обработано за один
            запрос</label
          >
          <input type="number" v-model="UsersPerTick" />
        </div>
      </div>
    </details> -->

    <div class="CompetenceTransfer">
      <div class="SectorsWithSections">
        <div class="Section">
          <span>Секция из которой нужно сделать трансфер</span>
          <select v-model="TransferFromSection" @change="getLevelsFrom">
            <option :value="-1" selected>Выберите секцию</option>
            <option
              v-for="(sect, sindx) in GetAllSections"
              :key="`${sindx}${sect.id}${sect.name}`"
              :value="sect.id"
              >{{ sect.name }}</option
            >
          </select>
        </div>

        <div class="Section">
          <span>Итоговая секция для зачисления</span>
          <select v-model="TransferToSection" @change="loadTemplate">
            <option :value="-1" selected>Выберите секцию</option>

            <option
              v-for="(sect, sindx) in GetAllAvailableSection"
              :key="`${sindx}${sect.id}${sect.name}`"
              :value="sect.id"
              >{{ sect.name }}</option
            >
          </select>
        </div>
      </div>

      <div class="TransformTemplate" v-if="TransferToSection != -1">
        <div
          class="TemplateRule"
          v-for="(temp, tindx) in TransferTemplate"
          :key="`${tindx}`"
        >
          <input
            type="text"
            :value="
              `${tindx + 1} (Максимальный уровень компетенции пользователя)`
            "
            readonly
          />

          <span>=</span>

          <input
            type="text"
            placeholder="1-1 уровни (будет выдано)"
            mask="1-# уровни (будет выдано)"
            v-model="temp.to"
            @input="setLevelMask($event, tindx)"
          />
        </div>
      </div>

      <div
        v-if="TransferToSection != -1"
        class="TransferButtons noselect"
        style="position: relative; display: flex; flex-direction: row; gap: 10px; width: fit-content; margin: 0% auto;"
      >
        <GradientButton
          v-if="TransferTemplate.length != 9"
          ButtonText="Добавить соответствие"
          @click.native="addTemplate"
        />
        <GradientButton
          v-if="TransferTemplate.length != 1"
          ButtonText="Удалить последнее соответствие"
          @click.native="removeTemplate"
        />
      </div>
    </div>

    <div class="Console">
      <template v-if="ConsoleCacheList.length == 0">
        <span style="width: 100%; text-align: center; color: #535353;"
          >Здесь пусто</span
        >
      </template>
      <template v-else>
        <span
          v-for="(text, tindx) in ConsoleCacheList"
          :key="`${text}-${tindx}`"
          >{{ text }}</span
        >
      </template>
    </div>
  </div>
</template>

<script>
import {
  getAllUsersChecker,
  postTransferCompetence,
  postDeclineTransferCompetence,
} from "@/api/system_checker.js";

import { getSectorsWithSections } from "@/api/competence.js";
import {
  loadTransferTemplate,
  saveTransferTemplate,
} from "@/api/competence_transfer.js";

import { getSection } from "@/api/competence.js";

import GradientButton from "@/components/CustomElements/ButtonGradient.vue";

export default {
  name: "AdminCheckCoinsAndAchievements",
  components: { GradientButton },
  data() {
    return {
      ConsoleCacheList: [],
      UsersList: [],

      UsersPerTick: 50,
      TickSleep: 800,

      ButtonsBlocked: false,
      IterationNumber: 0,
      LastIteration: false,
      IterationData: {},

      TransferTemplate: [
        {
          to: "1-1 уровни (будет выдано)",
        },
        {
          to: "1-2 уровни (будет выдано)",
        },
        {
          to: "1-3 уровни (будет выдано)",
        },
        {
          to: "1-4 уровни (будет выдано)",
        },
        {
          to: "1-5 уровни (будет выдано)",
        },
        {
          to: "1-6 уровни (будет выдано)",
        },
        {
          to: "1-7 уровни (будет выдано)",
        },
        {
          to: "1-8 уровни (будет выдано)",
        },
        {
          to: "1-9 уровни (будет выдано)",
        },
      ],

      TransferFromSection: -1,
      TransferToSection: -1,

      BlockCrossSectorTransfer: true,

      Sectors: [],

      SectionFromLevels: [],
      SectionToLevels: [],
    };
  },
  async created() {
    let _sectors_with_sections_resp = await getSectorsWithSections();
    this.Sectors = _sectors_with_sections_resp.data;
  },
  watch: {},
  computed: {
    UsersListWithCells() {
      return this.UsersList.filter((x) => x.user_cells.length > 0);
    },

    GetAllSections() {
      let sectionsNames = this.Sectors.map((sector) => {
        return sector.sections;
      }).reduce((acc, val) => acc.concat(val), []);

      return sectionsNames;
    },

    GetAllAvailableSection() {
      if (this.TransferFromSection == -1) {
        return [];
      }

      if (this.BlockCrossSectorTransfer) {
        let findSectorFromStartSection = this.Sectors.find((_sector) => {
          let _sector_sections = _sector.sections.map((_sect) => _sect.id);

          if (_sector_sections.includes(this.TransferFromSection)) {
            return _sector;
          }
        });

        return findSectorFromStartSection.sections.filter(
          (x) => x.id !== this.TransferFromSection
        );
      } else {
        return this.GetAllSections.filter(
          (x) => x.id !== this.TransferFromSection
        );
      }
    },
  },
  methods: {
    async resetIteration() {
      this.ConsoleCacheList = [];
      this.IterationNumber = 0;
      this.IterationData = {};
      this.LastIteration = false;
    },

    async getUsers() {
      await this.resetIteration();

      this.ConsoleCacheList = [
        `[${this.getTimeNow()}] Получение пользователей...`,
      ];
      this.ButtonsBlocked = true;

      await this.getUsersData();
    },
    async getUsersData() {
      this.IterationNumber++;

      if (this.LastIteration) {
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Пользователей было загруженно: ${
            this.UsersList.length
          } `
        );
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Получение списка пользователей завершеною`
        );

        this.UsersPerTick = 50;
        this.TickSleep = 1500;

        this.ButtonsBlocked = false;
        return 0;
      }

      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Номер итерации: ${this.IterationNumber}`
      );

      let timeStart = new Date().getTime();
      let _users_list_resp = await getAllUsersChecker(
        this.IterationNumber,
        this.UsersPerTick
      );
      let timeEnd = new Date().getTime();
      let MeasureTime = timeEnd - timeStart;

      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Получено ${
          _users_list_resp.data.length
        } пользователей (за ${MeasureTime} мс)`
      );
      if (_users_list_resp.data.length < this.UsersPerTick) {
        this.LastIteration = true;
      }

      this.UsersList = this.UsersList.concat(_users_list_resp.data);

      if (this.LastIteration) {
        await this.getUsersData();
      } else {
        setTimeout(async () => {
          await this.getUsersData();
        }, this.TickSleep);
      }
    },

    async checkTransfer() {
      await this.resetIteration();

      this.ConsoleCacheList = [
        `[${this.getTimeNow()}] Запущен процесс переноса компетенций...`,
      ];
      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Всего пользователей с компетенциями: ${
          this.UsersListWithCells.length
        }`
      );
      this.ButtonsBlocked = true;

      this.IterationData = {
        updatedUsersCount: 0,
      };

      await this.sendTransferData();
    },
    async sendTransferData() {
      this.IterationNumber++;

      if (this.LastIteration) {
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Пользователей обновленно: ${
            this.IterationData.updatedUsersCount
          } / ${this.UsersListWithCells.length}`
        );

        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Перенос компетенций завершен.`
        );

        this.ButtonsBlocked = false;
        return 0;
      }

      if (this.UsersPerTick * this.IterationNumber >= this.UsersList.length) {
        this.LastIteration = true;
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Номер итерации: ${
            this.IterationNumber
          } (Последний)`
        );
      } else {
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Номер итерации: ${this.IterationNumber}`
        );
      }

      let users_piece = [
        ...this.UsersListWithCells.slice(
          this.UsersPerTick * (this.IterationNumber - 1),
          this.UsersPerTick * this.IterationNumber
        ),
      ];

      users_piece = users_piece
        .map((user) => {
          let cells = user.user_cells.map((cell) => cell.level_id);
          let from_max_level = 0;
          for (let lvl of this.SectionFromLevels) {
            if (
              cells.includes(lvl.level_id) &&
              lvl.max_level > from_max_level
            ) {
              from_max_level = lvl.max_level;
            }
          }

          let to_set_level = 0;
          if (from_max_level != 0) {
            let templateIndx = -1;

            if (this.TransferTemplate.length >= from_max_level) {
              templateIndx = from_max_level - 1;
            } else {
              templateIndx = this.TransferTemplate.length - 1;
            }

            if (templateIndx != -1) {
              let value = this.TransferTemplate[templateIndx]["to"].replace(
                /\D/g,
                ""
              );
              to_set_level = value[1];
            }
          }

          return {
            id: user.id,
            max_level: from_max_level,
            set_levels: [...this.SectionToLevels.slice(0, to_set_level)]
              .map((lvl) => lvl.level_id)
              .filter((lvl) => !cells.includes(lvl)),
          };
        })
        .filter((user) => user.max_level != 0)
        .map((_usr) => {
          return {
            id: _usr.id,
            set_levels: _usr.set_levels,
            block_bonus_coin_for_section_id: this.TransferToSection,
          };
        });

      let req_data = {
        users: [...users_piece],
      };

      let timeStart = new Date().getTime();
      let _achievements_resp = await postTransferCompetence(req_data);
      let timeEnd = new Date().getTime();

      let MeasureTime = timeEnd - timeStart;

      this.ConsoleCacheList.unshift(
        _achievements_resp.data === 0
          ? `[${this.getTimeNow()}] Нет обновленных пользователей (${MeasureTime} мс)`
          : `[${this.getTimeNow()}] Обновлено ${
              _achievements_resp.data
            } пользователей (${MeasureTime} мс)`
      );

      this.IterationData.updatedUsersCount += _achievements_resp.data;

      if (this.LastIteration) {
        await this.sendTransferData();
      } else {
        setTimeout(async () => {
          await this.sendTransferData();
        }, this.TickSleep);
      }
    },

    async checkTransferDecline() {
      await this.resetIteration();

      this.ConsoleCacheList = [
        `[${this.getTimeNow()}] Запущен процесс отката компетенций...`,
      ];
      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Всего пользователей с компетенциями: ${
          this.UsersListWithCells.length
        }`
      );
      this.ButtonsBlocked = true;

      this.IterationData = {
        updatedUsersCount: 0,
      };

      await this.sendTransferDeclineData();
    },
    async sendTransferDeclineData() {
      this.IterationNumber++;

      if (this.LastIteration) {
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Пользователей обновленно: ${
            this.IterationData.updatedUsersCount
          } / ${this.UsersListWithCells.length}`
        );

        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Откат переноса компетенций завершен.`
        );

        this.ButtonsBlocked = false;
        return 0;
      }

      if (this.UsersPerTick * this.IterationNumber >= this.UsersList.length) {
        this.LastIteration = true;
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Номер итерации: ${
            this.IterationNumber
          } (Последний)`
        );
      } else {
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Номер итерации: ${this.IterationNumber}`
        );
      }

      let users_piece = [
        ...this.UsersListWithCells.slice(
          this.UsersPerTick * (this.IterationNumber - 1),
          this.UsersPerTick * this.IterationNumber
        ),
      ];

      users_piece = users_piece
        .map((user) => {
          let cells = user.user_cells.map((cell) => cell.level_id);
          let from_max_level = 0;
          for (let lvl of this.SectionToLevels) {
            if (
              cells.includes(lvl.level_id) &&
              lvl.max_level > from_max_level
            ) {
              from_max_level = lvl.max_level;
            }
          }

          return {
            id: user.id,
            max_level: from_max_level,
            set_levels: [...this.SectionToLevels].map((lvl) => lvl.level_id),
          };
        })
        .filter((user) => user.max_level != 0)
        .map((_usr) => {
          return {
            id: _usr.id,
            set_levels: _usr.set_levels,
            block_bonus_coin_for_section_id: this.TransferToSection,
          };
        });

      let req_data = {
        users: [...users_piece],
      };

      let timeStart = new Date().getTime();
      let _achievements_resp = await postDeclineTransferCompetence(req_data);
      let timeEnd = new Date().getTime();

      let MeasureTime = timeEnd - timeStart;

      this.ConsoleCacheList.unshift(
        _achievements_resp.data === 0
          ? `[${this.getTimeNow()}] Нет обновленных пользователей (${MeasureTime} мс)`
          : `[${this.getTimeNow()}] Обновлено ${
              _achievements_resp.data
            } пользователей (${MeasureTime} мс)`
      );

      this.IterationData.updatedUsersCount += _achievements_resp.data;

      if (this.LastIteration) {
        await this.sendTransferDeclineData();
      } else {
        setTimeout(async () => {
          await this.sendTransferDeclineData();
        }, this.TickSleep);
      }
    },

    setLevelMask(event, indx) {
      let input = event.target;
      let value = input.value.replace(/\D/g, ""); // Удаляем все нецифровые символы
      let formattedValue = "";

      if (input.value.length === 24) {
        formattedValue = `1-`;
      } else if (value.length == 1) {
        if (value[0] === 0 || value[0] === "0") {
          formattedValue = `1-`;
        } else {
          formattedValue = `1-${value[0]} уровни (будет выдано)`;
        }
      } else if (value.length == 2) {
        if (value[1] === 0 || value[1] === "0") {
          formattedValue = `1-`;
        } else {
          formattedValue = `1-${value[1]} уровни (будет выдано)`;
        }
      } else {
        formattedValue = `1-`;
      }

      this.TransferTemplate[indx]["to"] = formattedValue;
    },

    addTemplate() {
      this.TransferTemplate.push({
        to: `1-${this.TransferTemplate.length + 1} уровни (будет выдано)`,
      });

      this.checkAllTemplates();
    },
    removeTemplate() {
      this.TransferTemplate.pop();
    },

    checkAllTemplates() {
      for (let i = 0; i < this.TransferTemplate.length; i++) {
        this.setLevelMask(
          {
            target: {
              value: this.TransferTemplate[i].to,
            },
          },
          i
        );
      }
    },

    async loadTemplate() {
      await this.getLevelsTo();

      let template_resp = await loadTransferTemplate(
        this.TransferFromSection,
        this.TransferToSection
      );

      if (template_resp.data.length === 0) {
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Загружен шаблон по умолчанию`
        );
        this.TransferTemplate = [
          {
            to: "1-1 уровни (будет выдано)",
          },
          {
            to: "1-2 уровни (будет выдано)",
          },
          {
            to: "1-3 уровни (будет выдано)",
          },
          {
            to: "1-4 уровни (будет выдано)",
          },
          {
            to: "1-5 уровни (будет выдано)",
          },
          {
            to: "1-6 уровни (будет выдано)",
          },
          {
            to: "1-7 уровни (будет выдано)",
          },
          {
            to: "1-8 уровни (будет выдано)",
          },
          {
            to: "1-9 уровни (будет выдано)",
          },
        ];
      } else {
        this.TransferTemplate = [];

        for (let i of template_resp.data) {
          this.TransferTemplate.push({
            to: "1-1 уровни (будет выдано)",
          });
        }

        for (let i of template_resp.data) {
          this.TransferTemplate[i.fromLevel - 1][
            "to"
          ] = `1-${i.toLevel} уровни (будет выдано)`;
        }

        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Загружен шаблон из БД`
        );
      }
    },
    async saveTemplate() {
      let _levels = this.TransferTemplate.map((template, indx) => {
        let toLevel = template.to.replace(/\D/g, "");
        return {
          fromSection: this.TransferFromSection,
          toSection: this.TransferToSection,
          fromLevel: indx + 1,
          toLevel: parseInt(toLevel[1]),
        };
      });

      await saveTransferTemplate({
        from: this.TransferFromSection,
        to: this.TransferToSection,
        levels: _levels,
      });

      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Шаблон был сохранен`
      );
    },

    async getLevelsFromSection(section) {
      if (section == -1) {
        return [];
      }
      let LevelsFromSection_resp = await getSection(section);
      let LEVELS = [];
      let countL = 1;
      LevelsFromSection_resp.data.degrees.sort((a, b) => b.id - a.id);
      for (let i = 0; i < LevelsFromSection_resp.data.degrees.length; i++) {
        LevelsFromSection_resp.data.degrees[i].levels.sort(
          (a, b) => a.id - b.id
        );
        for (
          let j = 0;
          j < LevelsFromSection_resp.data.degrees[i].levels.length;
          j++
        ) {
          LEVELS.push(
            Object.assign(LevelsFromSection_resp.data.degrees[i].levels[j], {
              level: countL,
            })
          );
          countL++;
        }
      }
      return LEVELS.map((x, indx) => {
        return {
          max_level: indx + 1,
          level_id: x.id,
        };
      });
    },

    async getLevelsFrom() {
      this.TransferToSection = -1;

      this.SectionFromLevels = await this.getLevelsFromSection(
        this.TransferFromSection
      );

      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Загружены уровни начальной секции`
      );
    },
    async getLevelsTo() {
      this.SectionToLevels = await this.getLevelsFromSection(
        this.TransferToSection
      );

      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Загружены уровни конечной секции`
      );
    },

    async applyTransferForAllUsers() {
      let saveTemp = await this.saveTemplate();

      this.checkTransfer();
    },

    async declineTransferForAllUsers() {
      let saveTemp = await this.saveTemplate();

      this.checkTransferDecline();
    },

    getTimeNow() {
      var currentdate = new Date();
      var datetime =
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes() +
        ":" +
        currentdate.getSeconds();

      return datetime;
    },
  },
};
</script>

<style scoped>
.CompetenceTransfer {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;
}
.CompetenceTransfer > .SectorsWithSections {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 60px;

  height: fit-content;
  width: fit-content;

  margin: 0% auto;
}
.CompetenceTransfer > .SectorsWithSections > .Section {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  width: 300px;
  height: auto;

  flex-basis: auto;
}
.CompetenceTransfer > .SectorsWithSections > .Section > span {
  margin-bottom: auto;
  text-align: left;
}
.CompetenceTransfer > .SectorsWithSections > .Section > input {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;
}

.TransformTemplate {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: 0% auto;
  gap: 10px;

  width: fit-content;
  height: fit-content;
}
.TransformTemplate > .TemplateRule {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  width: fit-content;
  height: fit-content;
}
.TransformTemplate > .TemplateRule > input {
  width: 450px;
  text-align: center;
}
.TransformTemplate > .TemplateRule > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-size: 24px;
  line-height: normal;
  font-weight: 600;
}

.Advanced {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  margin: 0% auto;

  width: fit-content;
  height: fit-content;
}
.Advanced > summary {
  position: relative;
  display: block;

  margin: 0% auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
}

/* Убираем стандартный маркер Chrome */
.Advanced > summary::-webkit-details-marker {
  display: none;
}
/* Убираем стандартный маркер Firefox */
.Advanced > summary {
  list-style: none;
}

/* Добавляем собственный маркер для закрытого состояния */
.Advanced > summary:before {
  content: "\21A0";
  margin-right: 7px;
}

/* Добавляем собственный маркер для открытого состояния */
.Advanced[open] > summary:before {
  content: "\21A1";
}

.Tests {
  position: relative;

  padding-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;

  padding: 50px 8vw;

  width: 100%;
  min-height: 100vh;

  color: white;
}
.Buttons {
  position: relative;
  display: flex;

  padding: 30px 0%;

  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;
}

.Buttons > .Block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  width: fit-content;
  height: auto;
  flex-basis: auto;
}
.Buttons > .Block > label {
  position: relative;
  display: block;

  width: 300px;
  height: fit-content;
  text-align: left;

  margin-bottom: auto;
}

.Console {
  position: relative;
  display: flex;

  margin: 0% auto;

  padding: 60px 10px 10px 10px;

  width: 500px;
  height: 500px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  background-color: #1a1a1a;
  overflow-y: auto;
  border-radius: 12px;
}

.Console > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  text-align: left;
}

.Console::after {
  content: "Console";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #464646;
  text-align: left;
  padding: 10px;
}
</style>
