import request from "@/api/config.js";

export function loadTransferTemplate(fromSection, toSection) {
  return request({
    url: `competence_transfer/get/transfer-templates/${fromSection}/${toSection}`,
    method: "get",
  });
}

export function saveTransferTemplate(data) {
  return request({
    url: `competence_transfer/set/transfer-templates`,
    method: "post",
    data,
  });
}

export function getSectorsWithSectionsWithLevels() {
  return request({
    url: `competence_transfer/get/getSectorsWithSectionsWithLevels`,
    method: "get",
  });
}
